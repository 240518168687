

#EvaluationRateGrid_dialogEdit_wrapper {
    min-width: 60vw !important;
    min-height: 60vh  !important;
    max-width: 60vw !important;
    max-height: 65vh !important;
    // z-index: 758957;
  }
  div:has(#EvaluationRateGrid_dialogEdit_wrapper){
    z-index: 79534 !important;
  }

  div#EvaluationRateGrid_dialogEdit_wrapper_dialog-header {
    padding: 0px;
    padding-right: 10px;
    padding-top: 5px;
}