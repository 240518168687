// import '@syncfusion/ej2-base/styles/material.css';
// import '@syncfusion/ej2-navigations/styles/material.css';

.chart-of-account-wrapper{
   
    display: flex;
}
div:has(#chartOfAccountGrid_dialogEdit_wrapper){
    z-index: 9999 !important;
}

.e-checkbox-wrapper.e-wrapper {
    margin: 10px;
}

#inactive,
#transactional,
#aging,
#profile{
    justify-content: flex-start;
    display: flex;
    align-items: end;
}
#profile{
    justify-content: center;
}

#profile > button {
    background: #00cc67;
    color: black;
}
.toolbar {
    background-color: #5cc95e;
    color: #f4f4f4;
    padding: 5px;
    width: 98%;
    margin-left: 15px;
    margin-bottom: 10px;
}

.toolbar > .e-btn:hover,
.toolbar >  .e-css.e-btn:hover {
    background-color: white;
    color: #000;
}
.toolbar > .e-btn, .e-css.e-btn {
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    border-color: #f4f4f4;
    box-shadow: none;
    border-width: 1px ;
    border-style: solid;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    color: #f4f4f4;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 15px;
}
// #chart-of-account-edit-btn{
//     color: #ce3838;
// }

// .tree-view-chart-of-accoutn ul {
//     width: 100% 
//     !important;
// }
.css-smkl36-MuiCollapse-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-wrap: wrap;
}
.tree-view-chart-of-accoutn {
    width: 30%;
    overflow-x: hidden;
}
.yarn-contract-contaiSSner {
    width: 70%;
}

.loader-div {
    min-height: 400px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }

  .checkbox-cover-div-parent{
    position: relative;
  }
  .cover-div{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 111;
    opacity: 0;
    height: 100%;
    background-color: red;
  }

  div#SalesTaxGrid .e-gridcontent .e-content,
  div#IncomeTaxExemptionCertificateGrid .e-gridcontent .e-content,
  div#IncomeTaxGrid .e-gridcontent .e-content
  {

    height: auto !important;
  }










  .tooltip-btn {
    position: relative;
  }
//     display: inline-block;
//     padding: 10px 20px;
//     background-color: #3498db;
//     color: #fff;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//   }
.tooltip-btn::before {
    content: "Add in Current Level";
    position: absolute;
    visibility: hidden;
    top: -110%;
    background-color: #fff;
    color: #000;
    // font-weight: 600;
    text-align: center;
    border-radius: 4px;
    padding: 1px 4px;
    white-space: nowrap;
    // letter-spacing: 1px;
    z-index: 1;
    opacity: 0;
    font-size: 10px;
    transition: opacity 0.3s;
    left: 0px;
}
  .tooltip-btn:hover::before {
    visibility: visible;
    opacity: 1;
  }
  
  div#chartOfAccountGrid_dialogEdit_wrapper_dialog-header {
    padding: 10px;
}


#chartOfAccountGrid_dialogEdit_wrapper {
  min-width: 95vw !important;
  min-height: 95vh  !important;
  max-width: 95vw !important;
  max-height: 95vh !important;
  // z-index: 758957;
}

.chart-of-account-form-cover {
  width: 100%;
  height: 100%;
  // background: #ff000000;
  display: block;
  z-index: 3333;
  position: absolute;
  top: 0px;
}
.chart-of-account-form {
  position: relative;
}

.tree-control_wrapper {
  max-width: 500px;
  margin: auto;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

/* Style for selected node */

.white-node > .e-text-content .e-list-text {
  color: white !important; /* Optional: Change text color for better visibility */
}
.dark-node > .e-text-content .e-list-text {
  color: #003f55 !important; /* Optional: Change text color for better visibility */
}


.search-bar-chOfAcc {
  margin: 0px 10px;
  max-width: 30%;
}