.common_colour {
    color: #fff;
}
.company {
	color: white;
}

.submit_btn {
	background-color:#fff;
	border-radius:28px;
	border:1px solid #fff;
	display:inline-block;
	cursor:pointer;
	color:#000;
	font-family:Arial;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px #2f6627;
}
.submit_btn:hover {
	background-color:#5cbf2a;
}
.submit_btn:active {
	position:relative;
	top:1px;
}
.button_container {
	width: 100%;
	text-align: center;
}
.footer {
	position: fixed;
	right: 0;
	bottom: 0;
}

.link {
	cursor: pointer;
}

#username,
#password,
#email,
#newPassword,
#rePassword{
	text-transform: none !important;
}

// .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5 {
//     background: rgb(0 180 96);
// }

.MuiFormLabel-root.Mui-focused {
    color: #fff;
}
.MuiInputBase-input{
	color: #fff
	
	;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
}

label#password-label {
    color: #fff;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
    
    border-width: 2px;
}

label#username-label ,
label#email-label,
input#email,
input#newPassword,
label#newPassword-label,
label#newPassword-label,
input#rePassword,
label#rePassword-label,
label#email-label{
    color: #fff;
}

input#username,
input#password {
    color: #fff;
}
// .MuiLink-underlineHover:hover{
// 	text-decoration-color: #Fff !important;
// }
// .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5 {
//     background: #00CC66;
// }
ul.strong-password-ul {
    margin-left: 25px;
    margin-top: 10px;
}