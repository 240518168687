
.template-list {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-left: 20px;
    align-items: flex-start;
    // min-height: 80px;
  }
  div#header-checkbox div {
    margin-left: 10px;
  }
  .template-save {
    DISPLAY: FLEX;
    //  WIDTH: 100%;
    JUSTIFY-CONTENT: flex-start;
    ALIGN-ITEMS: center;
    // flex-wrap: wrap;
  }
  .template-save >button {
    margin-left: 10PX;
    padding: 6px;
    min-width: 150px;
    background: #09325b;
    outline: none;
    border: none;
    color: white;
    margin-right: 10px;
  }
  
  #reportName {
  min-width: 150px;
  }
  
  .drop-down-div > div{
    margin-top: 0px !important;
   
  }
  #templateName {
     
      height: 31px;
      padding: 10px;
      box-sizing: border-box;
      color: #a6a6a6;
      outline: none;
      border: 1px solid #a6a6a6;
  }
  .drop-down-div {
    // width: 90%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .e-radio-wrapper.e-wrapper:has(.orientation) {
    margin-left: 10px;
  }
  #dialog-btn {
    display: flex;
    justify-content: center;
  }
  div#header-checkbox {
    margin-left: 10px;
    min-width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  #save-btn{
    // margin-bottom: 10px;
    margin-top: 10px;
  }
  .template-save > span{
    margin-bottom: 10px;
    margin-top: 10px;
    min-width: 150px;
  }
  @media (max-width : 800px) {
    .template-save{
      flex-wrap: wrap;
      width: 100% !important;
    }
    .template-save  > span{
      width: 200px !important; 
    }
   
      #reportName {
      width: 200px;
      }
      .template-list{
        width: 100% !important;
        padding-right: 10px;
        
      }
      .drop-down-div{
        width: 100%;
        flex-wrap: wrap;
      }
      .drop-down-div .e-control-wrapper.e-input-group.e-ddl.e-float-input{
        max-width: 250px !important;
        margin-bottom: 10px;
      }
  }
  
  button.toggle-templete {
    font-size: 16px;
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    
    color: white;
    background-color: #09325b;
    vertical-align:middle
  }
  button.toggle-templete.active {
    color: #09325b;
    background: white;
    
  
  }
  .template-list {
    height: 0px;
    overflow: hidden;
    justify-content: flex-start;
    transition: all 0.2s linear;
    
  }
  .template-list.active {
    height: 100px;
    transition: all 0.3s linear;
  }



//   ********************************************************
//   CODE FOR VOMS
// #header-checkbox{
//     visibility: hidden;
// }



.tip-content{
  text-transform: uppercase;
  background-color: #fcdbe4;
  border-color: #fcdbe4;
  font-family: Arial, Helvetica, sans-serif;
  color: #f44336;
  max-width: 360px;
  min-width: 30px;
  padding: 0;
  text-align: center;
  font-weight: 700;
  visibility: visible;
  padding: 12px;
  word-break: break-word;
  // z-index: 888;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
}
// .tip-content::after {
//   content: "";
//   position: absolute;
//   top: -8px;
//   left: 50%;
//   transform: translateX(-50%);
//   border-width: 0 5px 10px 5px; /* Change size as needed */
//   border-style: solid;
//   background-color: #fcdbe4;
//   border-color: transparent transparent #fcdbe4 transparent; /* Change color as needed */
//   // filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
// }
// #check-contact-nos{
//   display: flex;
//   justify-content: center;
//   padding-top: 10px;
//   z-index: 8;

//   position: absolute;
//   width: 100% !important;
// }

#check-contact-nos {
  display: flex;
  padding-top: 10px;
  z-index: 8;
  position: absolute;
  /* width: 100% !important; */
  right: 15px ;
  transition: 3ms;
}

#exist-template{
  visibility: hidden;
}