.yarn-contract-container {
  margin-left: 100px;

  .sale-purchase {
    margin: 8px;
    min-width: 150px;
  }
}

.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.godown-add {
  float: right;
}

.users-container {
  max-height: 66vh;
  max-width: 58vw;
}
div:has(#UsersGrid_dialogEdit_wrapper){
  z-index: 252444 !important;
}

.checkbox-container {
  padding-top: 25px;
}

#UsersGrid {
  padding-left: 14px;
}
p#error {
  font-size: 10px;
  color: red;
  text-align: left;
  padding-left: 3px;
  padding-top: 3px;
  // margin: 0px;
}

.errorSpan {
  font-size: 12px;
  color: red;
  padding-left: 5px;
  margin-top: 5px;
}

// #UsersGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat {
//   visibility: hidden;
// }