

#ShopTransferGrid_dialogEdit_wrapper {
    min-width: 90vw !important;
    min-height: 90vh  !important;
    max-width: 90vw !important;
    max-height: 95vh !important;
    // z-index: 758957;
  }
  div:has(#ShopTransferGrid_dialogEdit_wrapper){
    z-index: 79534 !important;
  }

  div#ShopTransferGrid_dialogEdit_wrapper_dialog-header {
    padding: 0px;
    padding-right: 10px;
    padding-top: 5px;
}
div#ShopTransferDetailGrid .e-gridcontent .e-content{
  height: auto !important;
  max-height: 200px !important;
}

input#ShopTransferDetailGridTransferQty

 {
  text-align: right;
  padding: 2px;
}

#ToShopName_popup{
  visibility:  visible !important;
}
#ShopTransferDetailGridProductName_popup,
#ShopTransferDetailGridBrandName_popup,
#ShopTransferDetailGridFromShopName_popup,
#ShopTransferDetailGridToShopName_popup

{
  min-width: fit-content;
 
}

#ProductName_popup{
  visibility: visible !important;
}
.template-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.template-row {
  display: flex;
  // background-color: gray;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

// tr.template-row:hover {
//   background-color: #808080a3 !important;
//   // z-index: 22;
// }


.e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
  background-color: #bfdaf1;
  // color: #2319b8;
}
.header-row {
  font-weight: bold; /* Bold text for the header */
  text-align: center; /* Center align header cells */
}

.template-cell {
  flex: 1;
  text-align: left;
  // border-right: 1px solid;
  font-size: 13px;
  color: black;
  // padding: 10px;
  gap: 10;

}

.header-row .template-cell {
  text-align: center; /* Center align header cells */
}
// .e-ddl.e-popup .e-list-item:hover {
//   background-color: gray;
// }


// .e-ddl.e-popup .e-list-item:hover,
// .e-ddl.e-popup .e-list-item.e-active {
//     background-color: gray;
// }
