

#CurrencyTransferGrid_dialogEdit_wrapper {
    min-width: 90vw !important;
    min-height: 90vh  !important;
    max-width: 90vw !important;
    max-height: 95vh !important;
    // z-index: 758957;
  }
  div:has(#CurrencyTransferGrid_dialogEdit_wrapper){
    z-index: 79534 !important;
  }

  div#CurrencyTransferGrid_dialogEdit_wrapper_dialog-header {
    padding: 0px;
    padding-right: 10px;
    padding-top: 5px;
}