.setup-add-container {
  // margin-left: 100px;

  .sale-purchase {
    margin: 8px;
    min-width: 150px;
  }
}

.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.godown-add {
  float: right;
}
.e-dlg-container.e-dlg-center-center{
  // position: relative !important;
}