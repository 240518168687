

#VoucherGrid_dialogEdit_wrapper {
    min-width: 90vw !important;
    min-height: 90vh  !important;
    max-width: 90vw !important;
    max-height: 95vh !important;
    // z-index: 758957;
  }
  div:has(#VoucherGrid_dialogEdit_wrapper){
    z-index: 79534 !important;
  }

  div#VoucherGrid_dialogEdit_wrapper_dialog-header {
    padding: 0px;
    padding-right: 10px;
    padding-top: 5px;
}
div#VoucherDetailGrid .e-gridcontent .e-content{
  height: auto !important;
  max-height: 200px !important;
}

input#VoucherDetailGridDebit,
input#VoucherDetailGridCredit

 {
  text-align: right;
  padding: 2px;
}


#VoucherDetailGridAccountTitle_popup

{
  min-width: fit-content;
}


#MainTranfer_popup{
  // overflow-x: scroll;
  min-width: 50vw !important;
  max-width: max-content !important;
  position: absolute !important;
  // top: 50% !important; 
  left: 65% !important;
  transform: translateX(-50%) !important;
  
}

.template-container {
  width: 100%;
  display: flex;
  // overflow-x: scroll;

  flex-direction: column;
}

.template-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px;

  text-transform: uppercase;
  letter-spacing: 1px;
}

.header-row {
  font-weight: bold;
  text-align: center;
}

.template-cell {
  text-align: left;

  font-size: 11px;
  color: black;
  word-wrap: break-word !important; /* Ensure text wraps */
  padding: 10px;
  // gap:10px;
  border-right: 1px solid #ccc;
  // word-break: break-word; /* Ensure text wraps on word boundaries */
  white-space: normal !important;  
}

.header-row .template-cell {
  text-align: center;
  word-wrap: break-word !important;
  white-space: normal !important;
  font-size: 12px;
  border: none;
}

/* Specific widths for columns in percentage */
.column-1 {
  width: 20%;
}

.column-2 {
  width: 10%;
}


// .template-container {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// }

// .template-row {
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   padding: 5px;
//   text-transform: uppercase;
//   letter-spacing: 1px;
// }

// .header-row {
//   font-weight: bold;
//   text-align: center;
//   display: flex;
//   border: none !important;
//   background-color: #00cc67;
//   color: #000 !important;
//   width: 100%;
//   justify-content: space-between;
// }

// .template-cell {
//   text-align: left;
//   font-size: 10px;
//   color: black;
//   word-wrap: break-word; /* Ensure text wraps */
//   padding: 10px;
//   flex: 1; /* Make cells flexible */
//   border-right: 1px solid #ccc;
//   font-weight: bold;
//   white-space: normal;
// }

// .header-row .template-cell {
//   text-align: center;
//   word-wrap: break-word !important;
//   white-space: normal !important;
//   border: none;
// }

.template-cell:last-child {
  border-right: none; /* Remove border from the last cell */
}

.header-row .template-cell:last-child {
  border-right: none; /* Remove border from the last cell in the header */
}
