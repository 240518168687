

#InvoiceGrid_dialogEdit_wrapper {
    min-width: 90vw !important;
    min-height: 90vh  !important;
    max-width: 90vw !important;
    max-height: 95vh !important;
    // z-index: 758957;
  }
  div:has(#InvoiceGrid_dialogEdit_wrapper){
    z-index: 79534 !important;
  }

  div#InvoiceGrid_dialogEdit_wrapper_dialog-header {
    padding: 0px;
    padding-right: 10px;
    padding-top: 5px;
}

input#InvoiceDetailGridInvoiceQuantity,
input#InvoiceDetailGridInvoiceRate,
input#InvoiceDetailGridInvoiceAmount,
input#InvoiceDetailGridWeightPerBale,
input#InvoiceDetailGridInvoiceWeight
 {
  text-align: right;
  padding: 2px;
}


#InvoiceDetailGridProductName_popup,
#InvoiceDetailGridBrandName_popup,
#InvoiceDetailGridUnitOfMeasurementWeight_popup

{
  min-width: fit-content;
}

.template-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.template-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.template-row:hover {
}

.header-row {
  font-weight: bold; /* Bold text for the header */
  text-align: center; /* Center align header cells */
}

.template-cell {
  flex: 1;
  text-align: left;
  // border-right: 1px solid;
  font-size: 13px;
  color: black;
  // padding: 10px;
  gap: 10;

}

.header-row .template-cell {
  text-align: center; /* Center align header cells */
}
span.error-tooltip {
  color: red;
  letter-spacing: 1px;
}