@import './assets/syncfusion/ej2-base/styles/fabric.css';
@import './assets/syncfusion/ej2-buttons/styles/fabric.css';
@import './assets/syncfusion/ej2-calendars/styles/fabric.css';
@import './assets/syncfusion/ej2-dropdowns/styles/fabric.css';
@import './assets/syncfusion/ej2-inputs/styles/fabric.css';
@import './assets/syncfusion/ej2-navigations/styles/fabric.css';
@import './assets/syncfusion/ej2-popups/styles/fabric.css';
@import './assets/syncfusion/ej2-splitbuttons/styles/fabric.css';
@import './assets/syncfusion/ej2-react-grids/styles/fabric.css';

@import './assets/syncfusion/ej2-base/styles/material.css';
@import './assets/syncfusion/ej2-navigations/styles/material.css';

// @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
// @import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
// @import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
// @import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.App {
  text-align: center;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.5em;
  font-weight: bold;
}

.e-grid .e-headercelldiv {
  font-size: 11px;
}
// .e-grid .e-headercelldiv {
//   overflow-wrap: break-word;
//   word-wrap: break-word;
//   // div{display:table-caption;}
//   // e-headertext{display:table-caption;}
//   word-spacing: 9999rem;
// }

[class*=sidebar-light-] .sidebar a {
  color: #000;
  text-transform: uppercase;
}

[class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link {
  color: #000;
  // width: 250%;
}

.brand-link {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 0.8125rem 0.5rem;
    transition: width .3s ease-in-out;
    white-space: nowrap;
    text-align: center;
}

.sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link.active {
  background-color: #28a745;
  color: #fff;
  font-weight: bold;
}

.nav-link {
    display: block;
    padding: 0.5rem 0rem 0.5rem 0rem;
    padding-top: 0.5rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
}

[class*=sidebar-light] .list-group-item .search-path {
  color: #062e52;
  font-size: 15px;
}

// Sidebar Color
[class*=sidebar-light-] {
    background-color: #fff;
    color: #000;
    width: 258px;

}

.text-light {
  color: #5CC95E!important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #c8c8c8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li.nav-item {
  margin-top: 6px;
}


.os-theme-light>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
  background: #8a8c90;
}

.os-scrollbar-auto-hidden{
   opacity: 1 !important; 
   visibility: visible !important;
  // pointer-events: none;
}
.os-scrollbar-auto-hidden:hover{
   opacity: 1 !important; 
   visibility: visible !important;
  // pointer-events: none;
}
.os-scrollbar .os-scrollbar-vertical .os-scrollbar-auto-hidden{
  opacity: 1 !important; 
   visibility: visible !important;
}
.os-theme-light > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover{
  background: #8a8c90;
}
// .content-wrapper {
//   position: relative;
// }

.e-gridcontent .e-content{
  overflow-y: auto !important;
  height: 250px !important;
 
}

.e-grid .e-filtered::before{
  color: red;
  font-weight: 800;
}
.filtered-column {
  background-color: #00988c; /* Set your preferred background color */
  font-weight: bold;
  color: red;
}

.content-wrapper{
  padding: 0px 15px;
}


// .e-input[disabled], .e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
//   -webkit-text-fill-color: rgba(255,255,255,.8);
//   background: #f4f4f4;
//   text-transform: uppercase;
//   font-family: Arial, Helvetica, sans-serif;
//   color: #f4f4f4;
//   border-color: #f4f4f4;
//   font-weight: 600;
// }
//SET COLOR FOR DISABLED CONTROLS
.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled, .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-disabled, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error).e-disabled .e-input-in-wrap {
  // background: #5CC95E;
   background: #bfdaf1;

  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  // color: white;
}
.e-input[disabled], 
.e-input-group .e-input[disabled],
 .e-input-group.e-control-wrapper .e-input[disabled],
  .e-input-group.e-disabled,
   .e-input-group.e-control-wrapper.e-disabled,
    .e-float-input input[disabled], 
    .e-float-input.e-control-wrapper input[disabled],
     .e-float-input textarea[disabled],
      .e-float-input.e-control-wrapper textarea[disabled],
       .e-float-input.e-disabled, 
       .e-float-input.e-control-wrapper.e-disabled {
  // -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  // background: #00cc67;
  background: #bfdaf1;
  text-transform: uppercase;
  -webkit-text-fill-color: black;
  font-family: Arial, Helvetica, sans-serif;
  // color: #f4f4f4;
  // border-color: #f4f4f4;
  font-weight: 600;
}
div.e-disabled > label.e-label-bottom
{
  // -webkit-text-fill-color: rgb(255, 255, 255) !important;
  -webkit-text-fill-color: black !important;
}

.e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled]{
  // -webkit-text-fill-color: black !important;
}
span.e-input-group-icon.e-spin-down {
  display: none !important;
}
span.e-input-group-icon.e-spin-up {
  display: none !important;
}

// .e-dialog .e-dlg-content{
//   line-height:4;
// }

.row {
  row-gap: 10px;
}

.e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
  background-color: #bfdaf1;
  // color: #2319b8;
}











// 1

/* Change grid header background color */
// .e-gridheader {
//   background-color: #f0f0f0; /* Light #00988c background */
// }

// /* Change grid cell background color */
// .e-rowcell {
//   background-color: #e0e0e0; /* Slightly darker #00988c */
// }

// /* Change the color of the text in the grid */
// .e-rowcell {
//   color: #333; /* Dark text color */
// }


// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   background: #e0e0e0 !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   background: #e0e0e0 !important;
// }

// .os-padding{
//   background-color: #e0e0e0 !important;
// }
// .content-wrapper{
//   background-color: #fff;
// }













// 2


// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   background: rgb(51, 40, 167) !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   background: #fff !important;
// }

// .os-padding{
//   background-color: rgb(51, 40, 167 ) !important;
// }
// .content-wrapper{
//   background-color: #fff;
// }

// [class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link {
//   color: #fff  !important;
// }
// [class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link:hover {
//   background-color: rgb(4 4 4 / 46%);
// }
// .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link.active {
//   background-color: #fff !important;
//   color: #000;
//   font-weight: bold;
// }
// .nav-sidebar .nav-treeview>.nav-item>.nav-link>.nav-icon {
//   width: 1.6rem;
//   color: #fff;
// }
// .navbar-light .navbar-nav .nav-link {

//   color:#fff;
// }






// #3


/* Change grid header background color */
.e-gridheader {
  // #theme1
  // background-color: #AC8968; /* Light #00988c background */
  // #theme2
  // background-color: #0fa4AF; /* Light #00988c background */

  // background-color: #0fa4AF; /* Light #00988c background */
}

// /* Change grid cell background color */
// .e-rowcell {
//   // #theme1
//   // background-color: #00988c; /* Slightly darker #00988c */
//   // #theme2
//   //  background-color: #afddEf; /* Slightly darker #00988c */

//    background-color: #afddEf; /* Slightly darker #00988c */
// }

// /* Change the color of the text in the grid */
// .e-rowcell {
//   color: #F5F5F5; /* Dark text color */ 
//   color: #F5F5F5; /* Dark text color */ 
// }




// aside.main-sidebar.sidebar-light-success.elevation-4 {
  // #theme1
  // background: #AC8968 !important;

//   background: #0fa4AF !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #0fa4AF !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: #0fa4AF !important;
// }
// .content-wrapper{ 
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;
// }


// .e-dlg-header-content,
// .e-dlg-content,
// .e-footer-content{
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;

// }









// ASIF BHAI THEME
// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: white !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: white !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: white !important;
// }
// .content-wrapper{ 
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;
// }
.e-rowcell {
  // #theme1
  // background-color: #00988c; /* Slightly darker #00988c */
  // #theme2
  //  background-color: #afddEf; /* Slightly darker #00988c */

  //  background-color: #7adfa8; /* Slightly darker #00988c */
}



// .e-dlg-header-content,
// .e-dlg-content,
// .e-footer-content{
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;

// }




//#00988c color  applied in grid background
// .e-grid .e-gridheader {
//   background-color: white;
//   border-bottom-color: white;
//   border-top-color: white;
  
// }
// .e-gridpager.e-control.e-pager.e-lib {
//   background: white;
// }
// .e-toolbar .e-toolbar-items,
// div#hscroll_10_nav_left,
// div#hscroll_10_nav_right,
// span.e-input-group.e-control-wrapper.e-alldrop.e-ddl.e-lib.e-keyboard.e-valid-input,
// .e-grid .e-content,
// .e-toolbar,
// .e-control .e-toolbar .e-lib .e-keyboard{
//   background-color:white !important;
// }
// .e-dlg-content,
// .e-footer-content,
// .e-dlg-header-content,
// .main-footer,
// .content-box,
// .content-wrapper,
// .sidebar,
// .main-sidebar
// {
//   background-color: white;
// }



































// this code use center heading and grid font size family and center text and unbold 


 .e-table  tbody tr td {
  font-weight: normal;
  // font-size: 16px;
  text-align: center !important;
  font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  border: 1px solid;
}
table {
  border: 1px solid;
}
span.e-headertext {
  letter-spacing: 0.6px;
}


.content-wrapper h3 {
  text-align: center;
}

table.e-table.e-sortfilter.e-tableborder {
  border: 1px solid;
}
th.e-headercell.e-fltr-icon.e-mousepointer {
  border: 1px solid black;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  font-weight: 400;
}

.content-wrapper h3 {
  text-align: center;
  font-weight: 400;
}
.e-grid .e-groupdroparea{
  font-weight: 400;
}
td.e-rowcell {
  line-height: 10px !important;
}
.e-grid .e-altrow{ 
  background-color: white;
}
.e-grid .e-groupdroparea{
  font-weight: 400 !important;
  
}

// tr:hover td {
//   border: none;
// }





.e-headercelldiv {
  color: black !important;
  background: #e7e7e7 !important;
}

th.e-headercell.e-fltr-icon.e-mousepointer {
  background: #e7e7e7 !important;
}

td.e-rowcell {
  line-height: 1 !important;
}

a.nav-link.active {
    text-transform: capitalize;
} 


















































// ASIF BHAI THEME 111324   No = 103

// span.e-headertext {
//   font-size: 13px;
//   text-transform: uppercase;
//   font-family: Calibri;
// }

// .e-table  tbody tr td {
//   font-weight: normal;
//   text-align: center !important;
//   font-family: Calibri;
//   border: 1px solid;
//   font-size: 16px;
// }


// .e-table  tbody tr td {
//   font-weight: normal;
//   // font-size: 16px;
//   text-align: center !important;
//   font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
//   border: 1px solid !important;
// }
// table {
//   border: 1px solid;
// }
// span.e-headertext {
//   letter-spacing: 0.6px;
// }


// .content-wrapper h3 {
//   text-align: center;
// }

// table.e-table.e-sortfilter.e-tableborder {
//   border: 1px solid;
// }
// th.e-headercell.e-fltr-icon.e-mousepointer {
//   border: 1px solid black;
// }

// .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
//   font-weight: 400;
// }

// .content-wrapper h3 {
//   text-align: center;
//   font-weight: 400;
// }
// .e-grid .e-groupdroparea{
//   font-weight: 400;
// }
// td.e-rowcell {
//   line-height: 10px !important;
// }
// .e-grid .e-altrow{
//   background-color: white;
// }
// .e-grid .e-groupdroparea{
//   font-weight: 400 !important;
  
// }

// // tr:hover td {
// //   border: none;
// // }





// .e-headercelldiv {
//   color: black !important;
//   background: #e7e7e7 !important;
// }

// th.e-headercell.e-fltr-icon.e-mousepointer {
//   background: #e7e7e7 !important;
// }

// .e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
//   color: black !important;
// }

// a.nav-link.active {
//     text-transform: capitalize;
// }




// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: white !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: white !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: white !important;
// }
// .content-wrapper{ 
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;
// }
// .e-rowcell {
//   // #theme1
//   // background-color: #00988c; /* Slightly darker #00988c */
//   // #theme2
//   //  background-color: #afddEf; /* Slightly darker #00988c */

//   //  background-color: #7adfa8; /* Slightly darker #00988c */
// }



// .e-dlg-header-content,
// .e-dlg-content,
// .e-footer-content{
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;

// }

// .e-grid .e-table {
//   border-collapse: collapse !important;
  
// }

// THEME NO = 103 END










// ASIF BHAI THEME 111324   No = 104 START

// span.e-headertext {
//   font-size: 16px;
//   text-transform: uppercase;
//   font-family: Calibri;
// }

// .e-table  tbody tr td {
//   font-weight: normal;
//   text-align: center !important;
//   font-family: Calibri;
//   border: 1px solid;
//   font-size: 16px;
// }


// .e-table  tbody tr td {
//   font-weight: normal;
//   // font-size: 16px;
//   text-align: center !important;
//   font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
//   border: 1px solid;
// }
// table {
//   border: 1px solid;
// }
// span.e-headertext {
//   letter-spacing: 0.6px;
// }


// .content-wrapper h3 {
//   text-align: center;
// }

// table.e-table.e-sortfilter.e-tableborder {
//   border: 1px solid;
// }
// th.e-headercell.e-fltr-icon.e-mousepointer {
//   border: 1px solid black;
// }

// .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
//   font-weight: 400;
// }

// .content-wrapper h3 {
//   text-align: center;
//   font-weight: 400;
// }
// .e-grid .e-groupdroparea{
//   font-weight: 400;
// }
// td.e-rowcell {
//   line-height: 10px !important;
// }
// .e-grid .e-altrow{
//   background-color: white;
// }
// .e-grid .e-groupdroparea{
//   font-weight: 400 !important;
  
// }

// // tr:hover td {
// //   border: none;
// // }





// .e-headercelldiv {
//   color: black !important;
//   background: #e7e7e7 !important;
// }

// th.e-headercell.e-fltr-icon.e-mousepointer {
//   background: #e7e7e7 !important;
// }

// .e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
//   color: black !important;
// }

// a.nav-link.active {
//     text-transform: capitalize;
// }




// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: white !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: white !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: white !important;
// }
// .content-wrapper{ 
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;
// }
// .e-rowcell {
//   // #theme1
//   // background-color: #00988c; /* Slightly darker #00988c */
//   // #theme2
//   //  background-color: #afddEf; /* Slightly darker #00988c */

//   //  background-color: #7adfa8; /* Slightly darker #00988c */
// }



// .e-dlg-header-content,
// .e-dlg-content,
// .e-footer-content{
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;

// }
// table {
//   border: 0PX solid;
// }
// .e-grid .e-table {
//   border-collapse: COLLAPSE;
//   table-layout: fixed;
//   width: 100%;
// }

// THEME NO = 104 END








// THEME NO = 105 START


// /* Change grid header background color */
// .e-gridheader {
//   // #theme1
//   // background-color: #AC8968; /* Light #00988c background */
//   // #theme2
//   background-color: #00988c; /* Light #00988c background */

//   // background-color: #0fa4AF; /* Light #00988c background */
// }

// /* Change grid cell background color */
// .e-rowcell {
//   // #theme1
//   background-color: #00988c; /* Slightly darker #00988c */
//   // #theme2
//   //  background-color: #afddEf; /* Slightly darker #00988c */

//   //  background-color: #afddEf; /* Slightly darker #00988c */
// }

// /* Change the color of the text in the grid */
// // .e-rowcell {
// //   color: #00e5b2; /* Dark text color */ 
// //   color: #00e5b2; /* Dark text color */ 
// // }




// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #00988c !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #00988c !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: #00988c !important;
// }
// .content-wrapper{ 
//   // #theme1
//   background-color: #00988c;

//   // background-color: ##00e5b2 ;
// }


// .e-dlg-header-content,
// .e-dlg-content,
// .e-footer-content{
//   // #theme1
//   background-color: #00988c;

//   // background-color: ##00e5b2 ;

// }

// .e-grid .e-icon-filter::before, .e-grid-menu .e-icon-filter::before {
//   content: "\e21c";
//   color: white;
// }
// .e-headercelldiv {
//   background: #004258 !important;
//   color: white !important;
// }
// th.e-headercell.e-fltr-icon.e-mousepointer {
//   background: #004258 !important;
// }

// .e-grid .e-icon-descending::before, .e-grid-menu .e-icon-descending::before {
//   content: "\e83f";
//   color: white;
// }
// .e-headercontent,
// .e-content {
//   background: #00988c !important;
// }
// .e-gridheader.e-lib.e-draggable.e-droppable {
//   background: #004157 !important;
// }


// .e-gridheader {
//     background-color: #004157 !important;
//     border-bottom-color: #004157 !important;
//     border-top-color: #004157 !important;
    
//   }
//   .e-gridpager.e-control.e-pager.e-lib {
//     background:#004157 !important;
//   }
//   .e-toolbar .e-toolbar-items,
//   div#hscroll_10_nav_left,
//   div#hscroll_10_nav_right,
//   span.e-input-group.e-control-wrapper.e-alldrop.e-ddl.e-lib.e-keyboard.e-valid-input,
 
//   .e-toolbar,
//   .e-control .e-toolbar .e-lib .e-keyboard{
//     background-color:#004157 !important;
//   }
//   .e-dlg-content,
//   .e-footer-content,
//   .e-dlg-header-content,
//   .main-footer,
//   .content-box,
//   .content-wrapper,
//   .sidebar,
//   .main-sidebar
//   {
//     background-color:#00988c;
//   }

//   div#hscroll_10  * {
//     background: #004258 !important;
//     color: white !important;
// }
  
  
// // div#BrandGrid_toolbarItems * {
// //   background: #004258;
// // }
// // div#BrandGrid_toolbarItems * {
// //   background: #004258 !important;
// //   color: white !important;
// // }
// .e-toolbar-items .e-lib .e-hscroll .e-control .e-touch * {
//   background: #004258;
// }
// .e-toolbar-items .e-lib .e-hscroll .e-control .e-touch * {
//   background: #004258 !important;
//   color: white !important;
// }

// .e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
//   background: #004258;
// }
// span.e-btn-icon {
//   color: white !important;
// }



// .e-pager .e-numericitem {
//   background: #004157;
//   border-right-color: #eaeaea;
//   text-transform: capitalize;
//   font-family: Arial, Helvetica, sans-serif;
//   color: white;
// }
// .e-pagercontainer * {
//   background: #004258 !important;
//   color: white !important;
// }
// .e-pager .e-pagercontainer{
//   background: #004258 !important;
//   color: white !important;
// }

// THEME NO = 105 END










// THEME NO = 106 START
// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   background: #233a64 !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {

//   background: #233a64 !important;
// } 

// .os-padding{

//   background-color: #233a64 !important;
// }
// .e-pager .e-numericitem {
//   background: #009f60;
//   border-right-color: #eaeaea;
//   text-transform: capitalize;
//   font-family: Arial, Helvetica, sans-serif;
//   color: white;
// }
// a.nav-link * {
//   color: white !important;
// }


// THEME NO = 106 END




















// // THEME NO = 107 START
// span.e-headertext {
//   font-size: 13px;
//   text-transform: uppercase;
//   font-family: Calibri;
// }

// .e-table  tbody tr td {
//   font-weight: normal;
//   text-align: center !important;
//   font-family: Calibri;
//   border: 1px solid;
//   font-size: 16px;
// }


// .e-table  tbody tr td {
//   font-weight: normal;
//   // font-size: 16px;
//   text-align: center !important;
//   font-family: ui-sans-serif, -apple-system, system-ui, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
//   border: 1px solid !important;
// }
// table {
//   border: 1px solid;
// }
// span.e-headertext {
//   letter-spacing: 0.6px;
// }

// .navbar-light .navbar-nav .nav-link{
//   color: white !important;
// }
// a.nav-link * {
//   color: white !important;
// }
// // /* Change grid header background color */
// .e-gridheader {
//    background-color: #33424F; /* Light #00988c background */

// }



// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #33424F !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #33424F !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: #33424F !important;
// }
// .dropdown-menu.dropdown-menu-lg.dropdown-menu-right.show > a {
//   color: red !important;
// }

// // THEME NO = 107 END












// // THEME NO = 108 START
// .navbar-light .navbar-nav .nav-link{
//   color: white !important;
// }
// a.nav-link * {
//   color: white !important;
// }
// // /* Change grid header background color */
// .e-gridheader {
//    background-color: #33424F; /* Light #00988c background */

// }



// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #33424F !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: #33424F !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: #33424F !important;
// }
// .dropdown-menu.dropdown-menu-lg.dropdown-menu-right.show > a {
//   color: red !important;
// }
// th.e-headercell.e-fltr-icon.e-mousepointer {
//   background: #4f81bd !important;
// }
// .e-headercelldiv {
//   background: #4f81bd !important;
// }
// // THEME NO = 108 END



































// THEME NO = 109 START
// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   background: #233a64 !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {

//   background: #233a64 !important;
// } 

// .os-padding{

//   background-color: #233a64 !important;
// }
// .e-pager .e-numericitem {
//   background: #009f60;
//   border-right-color: #eaeaea;
//   text-transform: capitalize;
//   font-family: Arial, Helvetica, sans-serif;
//   color: white;
// }
// a.nav-link * {
//   color: white !important;
// }
// th.e-headercell.e-fltr-icon.e-mousepointer {
//   background: #4f81bd !important;
// }
// .e-headercelldiv {
//   background: #4f81bd !important;
//   color: white;
// }
// .e-headercelldiv .e-headertext {
//   color: white;
// }
// .e-grid th.e-headercell[aria-sort=ascending] .e-headertext, .e-grid th.e-headercell[aria-sort=descending] .e-headertext, .e-grid th.e-headercell[aria-sort=ascending] .e-sortfilterdiv, .e-grid th.e-headercell[aria-sort=descending] .e-sortfilterdiv{
//   color: whitesmoke;
// }
// .e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
//   color: white !important;
// }
// THEME NO = 109 END














































// THEME NO = 110 START
// .e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
//   background-color: #bfdaf1;
//   // color: #2319b8;
// }

// aside.main-sidebar.sidebar-light-success.elevation-4 {
//   // #theme1
//   // background: #AC8968 !important;

//   background: LIGHTGRAY !important;
// }
// nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
//   // #theme1
//   // background: #AC8968 !important;

//   background: LIGHTGRAY !important;
// } 

// .os-padding{
//   // #theme1
//   // background-color: #AC8968 !important;

//   background-color: LIGHTGRAY !important;
// }
// .content-wrapper{ 
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;
// }
// .e-rowcell {
//   // #theme1
//   // background-color: #00988c; /* Slightly darker #00988c */
//   // #theme2
//   //  background-color: #afddEf; /* Slightly darker #00988c */

//    background-color: #7adfa8; /* Slightly darker #00988c */
// }



// .e-dlg-header-content,
// .e-dlg-content,
// .e-footer-content{
//   // #theme1
//   // background-color: #00988c;

//   background-color: #F5F5F5 ;

// }




// //#00988c color  applied in grid background
// .e-grid .e-gridheader {
//   background-color: LIGHTGRAY;
//   border-bottom-color: LIGHTGRAY;
//   border-top-color: LIGHTGRAY;
  
// }
// .e-gridpager.e-control.e-pager.e-lib {
//   background: LIGHTGRAY;
// }
// .e-toolbar .e-toolbar-items,
// div#hscroll_10_nav_left,
// div#hscroll_10_nav_right,
// span.e-input-group.e-control-wrapper.e-alldrop.e-ddl.e-lib.e-keyboard.e-valid-input,
// .e-grid .e-content,
// .e-toolbar,
// .e-control .e-toolbar .e-lib .e-keyboard{
//   background-color:LIGHTGRAY !important;
// }
// .e-dlg-content,
// .e-footer-content,
// .e-dlg-header-content,
// .main-footer,
// .content-box,
// .content-wrapper,
// .sidebar,
// .main-sidebar
// {
//   background-color: LIGHTGRAY;
// }

// THEME NO = 110 END


.e-headercontent {
  overflow-y: scroll !important;
}