.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.accessory-stock-container{
  min-height: 100vh;
  width: auto;
  background-color: white;
}

#accessoryBOMGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}

#accessoryBOMGrid_dialogEdit_wrapper {
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 95vh !important;
  // z-index: 758957;
}
div:has(#accessoryBOMGrid_dialogEdit_wrapper){
  z-index: 79534 !important;
}
#accessoryBOMAddGridPreviousProcurementRate,
#accessoryBOMAddGridRequiredQuantity,
#accessoryBOMAddGridTargetRate,
#accessoryBOMAddGridTargetAmount
{
  text-align: right;
  padding-right: 2px;
}
#accessoryBOMAddGridAccessoryCategory_popup{

  min-width: fit-content ; /* Adjust the width as needed */
  // display: block;
}


.accessory-stock-container {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

// #accessoryBOMGrid_dialogEdit_wrapper> .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat {
//   visibility: hidden;
// }

.duplicate {
  color: red;
  text-align: right;
  /* vertical-align: top; */
  /* margin-top: 57px; */
  background: #f4f4f4;
  transition: 0.3s;
  transform: scale(0);
}

.productName > table  tr >th,td {
  border: 0 none;
  // display: inline-block;
  font-size: 15px;
  font-weight: 600;
  // height: 29px;
  line-height: 29px;
  margin: 0;
  padding: 0px 15px;
  text-align: left;
  text-transform: uppercase;
  color: rgb(64, 161, 67);
  font-family: Arial, Helvetica, sans-serif;
  /* word-spacing: 100vw; */
  -webkit-user-select: none;
  // border: 1px solid #a6a6a6;
 
  user-select: none;
}
.productName table{
  outline: none;
  box-shadow: 1px 1px 5px #a6a6a6;
  margin-bottom: 15px;
  width: fit-content;
}
.productName > table > tbody > tr >td {
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400 !important;
}

.add-new {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  line-height: 1.5 !important;
}