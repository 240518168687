

:root {
    /*WHITE*/
    --white-bg-color: #fff;
    --white-text-color: #000;
  
    /*DARK*/
    --dark-bg-color: #434343;
    --dark-text-color: #fff;
  
    /*RED*/
    --red-bg-color: #ce8082;
    --red-text-color: #020202;
  
    /*GREEN*/
    --green-bg-color: #40de7c;
    --green-text-color: #020202;
  
    /*YELLOW*/
    --yellow-bg-color: #fdc011;
    --yellow-text-color: #110d02;
  
    /*BLUE*/
    --blue-bg-color: #2943a1;
    --blue-text-color: #d4cbcd;
  
    /*PURPLE*/
    --purple-bg-color: #dbdbed;
    --purple-text-color: #29262e;
  
    /*OLIVE*/
    --olive-bg-color: #838b74;
    --olive-text-color: #f7f5e4;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  a {
    color: inherit;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  [name="colors"] {
    position: fixed;
    left: -9999px;
  }
  
  label {
    cursor: pointer;
    z-index: 855;
    // grid: area 30px;;
  }
 
//   [id='green']:checked ~  label{

//          background-color: RED !important;
//         // display: block;
//         z-index: 888;

    
//   }
// body{
//     overflow: hidden !important;

// }
// #root{
//     overflow: hidden;
// }
  .page-wrapper {
    // padding: 30px 0;
    transition: all 0.3s ease-in-out;
    z-index: 978999;
  }
  .page-wrapper{
   
      position: absolute;
      top: 30px;
      right: 15px;
      
      top: 30px;
      right: 15px;
      display: flex;
      // transform:  translate(-2PX , 58PX);
  }
  
  .ui-switch{
    position: absolute;
    top: 30px;
    right: 15px;
    
    top: 30px;
    right: 15px;
    display: flex;
    // transform:  translate(-2PX , 58PX);
}
.ui-switch{

  top: 10px;
  position: absolute;
  transform: translateY(135px);
  right: 2px;
}
.icons {
    font-weight: 900;
    font-size: 24px;
    z-index: 545454;
    cursor: pointer;
    margin-top: 7px;
    margin-right: 7px;
}
.page-wrapper {
    transform: translateY(78px);
    margin-right: -249PX;
}
html {
  overflow-x: hidden;
  width: 100vw !important;
}
  .color-palette {
    // position: absolute;
    // top: 30px;
    // right: 15px;
    display: flex;
    // grid-row-gap: 10px;
    padding:7px;
    padding-top: 7px;
    border-radius: 20px;
    background: transparent;

    transition: 200ms linear;
    // background-color: #333;
    // background-color: #ffff;
    // background-color: #ffff;
    // box-shadow: inset rgb(38, 57, 77) 0px 20px 30px -10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    // box-shadow:  rgb(38, 57, 77) 0px 20px 30px -10px;
    z-index: 892141;
    // transform: rotate(90deg) translate(-35PX , 72PX);
  }
  
  .color-palette label {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 999999;
    margin-right: 8px;
    margin-left: 8px !important;
    margin-bottom: 0px !important;
  }
  .color-palette label[for="green"]{
    margin-left: 0px;
  }
  .color-palette label::before {
     display: none;
    content: "";
    position: absolute;
    top: -15px;
    left: 8px;
    transform: translateY(-50%);
    width: 2px;
    height: 10px;
    background: none;
  }
  
   

//   [id="white"] ~ .color-palette [for="white"] {
//     background: var(--white-bg-color);
//   }
  
//   [id="dark"] ~ .color-palette [for="dark"] {
//     background: var(--dark-bg-color);
//   }
  
//   [id="red"] ~ .color-palette [for="red"] {
//     background: var(--red-bg-color);
//   }
  
//   [id="green"] ~ .color-palette [for="green"] {
//     background: var(--green-bg-color);
//   }
  
//   [id="yellow"] ~ .color-palette [for="yellow"] {
//     background: var(--yellow-bg-color);
//   }
  
//   [id="blue"] ~ .color-palette [for="blue"] {
//     background: var(--blue-bg-color);
//   }
  
//   [id="purple"] ~ .color-palette [for="purple"] {
//     background: var(--purple-bg-color);
//   }
  
//   [id="olive"] ~ .color-palette [for="olive"] {
//     background: var(--olive-bg-color);
//   }
  
  .post img {
    display: block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
  
  .post h1 {
    font-size: 3rem;
    line-height: initial;
  }
  
  .post img,
  .post p,
  .post hr {
    margin-top: 30px;
  }
  
  .post hr {
    border: none;
    border-top: 1px dashed;
  }
  
  .post .credits {
    font-size: 1rem;
  }
  
  @media (max-width: 500px) {
    .container {
      padding: 0 80px 0 15px;
    }
  }
  
  
  /* CHECKBOX HACK STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
//   [type="radio"]:checked ~ .color-palette label::before {
   
//   }
  
 
  
  
  
  input[id="green"]:checked ~ .color-palette label[for="green"]::before {
    background: #00cc67 !important;
    display: block !important;
  }
  
  input[id="blue"]:checked ~ .color-palette label[for="blue"]::before {
    background: #3028a6 !important;
    display: block !important;
  }
  
  input[id="yellow"]:checked ~ .color-palette label[for="yellow"]::before {
    background: #fdc011 !important;
    display: block !important;
  }
  
  input[id="lightBlue"]:checked ~ .color-palette label[for="lightBlue"]::before {
    background: #008eff !important;
    display: block !important;
  }
  
  input[id="orange"]:checked ~ .color-palette label[for="orange"]::before {
    background: #ff8f01 !important;
    display: block !important;
  }
  input[id="pink"]:checked ~ .color-palette label[for="pink"]::before {
    background: #e83e8c !important;
    display: block !important;
  }
  
  
  
  
  /* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .page-footer {
    position: fixed;
    right: 15px;
    bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 5px;
  }
  
  .page-footer a {
    margin-left: 4px;
  }


  .color-palette > label.svg {
    /* margin-top: -4px; */
    background: #00000050;
    padding: 0px;
    transition: all 500ms;
}

svg#moon
 {
  margin-top: -8px;
 }
 svg.sun-and-moon {
  margin-top: -8px;
}