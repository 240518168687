@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

//   .rotateDiv{
//     animation: rotate 2s linear infinite;

//   }
  .rotateDiv::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 5px dotted white;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotate 5s linear infinite;
  }
  
  
    #send-email-btn {
        z-index: 1;
        background: white;
        border-radius: 50px;
    } 

